import { useNavigate, useLocation } from "react-router-dom";

const RejectAccess = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { reason, isAuthorized } = location.state || {};

  const goToSignUp = () => {
    navigate("/sign-up");
  };

  const handleSignInClick = () => {
    setIsAuthenticated(false);
    navigate("/sign-in", { state: { from: location.state.from } });
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="text-center">
        <div className="access-denied-message">
          {reason === "idle" ? (
            <>
              <h1 className="text-6xl font-bold">Session Idle </h1>{" "}
              <p className="mt-4 text-xl text-gray-500">
                Your session has expired. Please log in again to continue.
              </p>
            </>
          ) : (
            <>
              <h1 className="text-6xl font-bold">Access Denied</h1>
              <p className="mt-4 text-xl text-gray-500">
                You must be logged in to view this page.
              </p>
            </>
          )}
          {reason == "idle" && (
            <div className="mt-6">
              <button
                onClick={handleSignInClick}
                className="rounded bg-blue-500 px-6 py-3 text-lg text-white transition duration-300 hover:bg-blue-700"
              >
                Log In
              </button>
              <button
                onClick={goToSignUp}
                className="ml-4 rounded bg-blue-500 px-6 py-3 text-lg text-white transition duration-300 hover:bg-blue-700"
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectAccess;
