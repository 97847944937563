import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import { HiOutlineLightBulb } from "react-icons/hi";
import { CircleCheck } from "lucide-react";
import "tippy.js/dist/tippy.css";
import { sentences } from './sentencesData'; // Adjust the path based on your project structure
import { Sentence } from './Sentence'; // If needed

// 1) Group sentences into paragraphs via isParagraphStart
function groupParagraphs(sentences: Sentence[]): Sentence[][] {
  const paragraphs: Sentence[][] = [];
  let current: Sentence[] = [];

  for (const s of sentences) {
    if (s.isParagraphStart && current.length > 0) {
      paragraphs.push(current);
      current = [];
    }
    current.push(s);
  }
  if (current.length > 0) {
    paragraphs.push(current);
  }

  return paragraphs;
}

const Annotate: React.FC = () => {
  const [hoveredId, setHoveredId] = useState<string | number | null>(null);
  const [selectedEssay, setSelectedEssay] = useState<string | null>(null);

  // Dummy content for each essay
  const essayContent: Record<string, JSX.Element> = {
    A: (
      <div className="mt-6 rounded-lg bg-gray-100 p-4 shadow">
        <h2 className="mb-2 text-xl font-semibold">Essay A Content</h2>
        <p>Content for Essay A...</p>
      </div>
    ),
    B: (
      <div className="mt-6 rounded-lg bg-gray-100 p-4 shadow">
        <h2 className="mb-2 text-xl font-semibold">Essay B Content</h2>
        <p>Content for Essay B...</p>
      </div>
    ),
    C: (
      <div className="mt-6 rounded-lg bg-gray-100 p-4 shadow">
        <h2 className="mb-2 text-xl font-semibold">Essay C Content</h2>
        <p>Content for Essay C...</p>
      </div>
    ),
    D: (
      <div className="mt-6 rounded-lg bg-gray-100 p-4 shadow">
        <h2 className="mb-2 text-xl font-semibold">Essay D Content</h2>
        <p>Content for Essay D...</p>
      </div>
    ),
    E: (
      <div className="mt-6 rounded-lg bg-gray-100 p-4 shadow">
        <h2 className="mb-2 text-xl font-semibold">Essay E Content</h2>
        <p>Content for Essay E...</p>
      </div>
    ),
  };

  // Filter for whichever essay is selected
  const filteredSentences = sentences.filter((s) => s.title === selectedEssay);

  // Group sentences into paragraphs
  const paragraphs = groupParagraphs(filteredSentences);

  return (
    <>
      {/* Buttons */}
      <div className="my-6 flex flex-wrap justify-center gap-4">
        {["A", "B", "C", "D", "E"].map((essay) => (
          <button
            key={essay}
            onClick={() => setSelectedEssay(essay)}
            className={`rounded-lg px-4 py-2 font-semibold text-white shadow-md transition duration-200 focus:outline-none focus:ring-2 ${
              selectedEssay === essay
                ? "bg-blue-600 focus:ring-blue-300"
                : "bg-green-500 hover:bg-green-600 focus:ring-green-300"
            }`}
          >
            Essay {essay}
          </button>
        ))}
      </div>

      {selectedEssay && (
        <div className="mx-auto max-w-6xl p-6">
          <h1 className="mb-8 text-center text-2xl font-bold text-gray-800">
            {selectedEssay === "A"
              ? "Identity the causes of burnout in the workplace and suggest ways to improve working environment for the employees"
              : selectedEssay === "B"
                ? "Analyse the adverse effects of sleep deprivation on academic performance among university students and suggest ways to mitigate these negative effects."
                : "Essay Title Here"}
          </h1>

          {/* Render selected essay content */}
          {essayContent[selectedEssay]}

          {/* Render paragraphs in two columns */}
          <div className="mt-6 space-y-12">
            {paragraphs.map((paragraph, idx) => (
              <div key={idx} className="grid grid-cols-1 gap-12 md:grid-cols-2">
                {/* LEFT COLUMN: all sentences in one paragraph */}
                <div className="rounded-lg bg-white p-8 shadow">
                  {/* 
                    Add whitespace-nowrap so there's ZERO line wrapping.
                    If you do want normal wrapping, remove whitespace-nowrap.
                  */}
                  <p className="text-gray-800">
                    {paragraph.map((sentence) => {
                      const isHovered = hoveredId === sentence.uniqueId;

                      // Each sentence is just a space after the previous
                      const sentenceSpan = (
                        <span
                          className={`relative inline rounded border px-1.5 py-0.5 text-base transition-colors duration-200 ${
                            isHovered ? "bg-blue-200" : ""
                          } ${
                            sentence.icon === "tick" || sentence.icon === "NA"
                              ? "border-blue-300"
                              : "border-red-500"
                          }`}
                          onMouseEnter={() => setHoveredId(sentence.uniqueId)}
                          onMouseLeave={() => setHoveredId(null)}
                        >
                          {/* ID badge */}
                          <span
                            className={`absolute -top-2.5 left-1 bg-white px-1 text-xs font-medium ${sentence.icon === "tick" || sentence.icon === "NA"? "text-blue-500": "text-red-500"}`}
                          >
                            {sentence.id}
                          </span>
                          {sentence.text}{" "}
                        </span>
                      );

                      return (
                        <React.Fragment key={sentence.uniqueId}>
                          {/* Desktop/Tablet */}
                          <span className="hidden md:inline">
                            {sentenceSpan}
                          </span>

                          {/* Mobile with Tippy */}
                          <span className="md:hidden">
                            {sentence.annotation?.trim() ? (
                              <Tippy
                                content={
                                  <div
                                    className={`p-2 text-sm ${
                                      sentence.icon === "tick"
                                        ? "text-white"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {sentence.annotation}
                                  </div>
                                }
                                interactive={true}
                                trigger="mouseenter"
                              >
                                {sentenceSpan}
                              </Tippy>
                            ) : (
                              sentenceSpan
                            )}
                          </span>
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>

                {/* RIGHT COLUMN: annotations */}
                <div className="hidden font-comic md:block">
                  {paragraph.map((sentence) => {
                    const { uniqueId, id, annotation, icon } = sentence;
                    if (!annotation) return null;

                    const isHovered = hoveredId === uniqueId;

                    return (
                      <div
                        key={uniqueId}
                        className={`relative rounded border bg-white p-2 transition-colors duration-200 ${
                          icon === "tick"
                            ? "border-blue-500"
                            : "border-red-500"
                        } ${isHovered ? "bg-blue-200" : ""} mt-6 shadow`}
                        onMouseEnter={() => setHoveredId(uniqueId)}
                        onMouseLeave={() => setHoveredId(null)}
                      >
                        <div
                          className={`flex items-center ${
                            isHovered ? "bg-blue-200" : ""
                          }`}
                        >
                          <div className="pl-2 text-sm text-green-600">
                            {icon === "tick" && (
                              <CircleCheck className="h-8 w-8" />
                            )}
                            {icon === "light_bulb" && (
                              <HiOutlineLightBulb className="h-8 w-8 text-red-500" />
                            )}
                          </div>
                          <button className="flex-shrink-0 rounded-full bg-gray-100 p-1.5 text-sm">
                            {id}
                          </button>

                          <div className="pl-2 text-xs text-gray-600">
                            {annotation}
                          </div>
                        </div>

                        {/* optional connecting line on the left */}
                        <div className="absolute left-0 top-1/2 -translate-x-full -translate-y-1/2 transform">
                          <div className="h-px w-6 bg-blue-200"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Annotate;
