import React, { useContext, useState } from "react";
import { UserContext } from "./../App";
import SettingsModal from "./SettingsModal";
import { useNavigate } from "react-router-dom";

import { mainTopics } from "./topics";
import useEscapeKeyHandler from "./../hooks/useEscapeKeyHandler";
import useOutsideClick from "../hooks/useOutsideClick";
import useExercises from "./../hooks/useExercises";
import axios from "axios";

const Dashboard = () => {
  const { user, setUser, isLoading } = useContext(UserContext);
  const { exercises, loading, error } = useExercises();
  const [scoreOpen, setScoreOpen] = useState(false);
  const navigate = useNavigate();

  const [profileOpen, setProfileOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isEditingNameModalOpen, setIsEditingNameModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [isWaiting, setIsWating] = useState(true);

  let subtopicDisplayNames = {};
  let max_score_mapping = {};
  exercises.forEach((exercise) => {
    max_score_mapping[exercise.id] = exercise.maxScore;
    subtopicDisplayNames[exercise.id] = exercise.displayName;
  });

  const handleSettingChange = () => {
    setIsSettingsModalOpen(true);
  };

  const handleEdit = () => {
    setIsEditingNameModalOpen(true);
    setNewUserName(user.name);
    setIsEditing(true);
  };

  const handleSave = () => {
    const lowerCaseNewUserName = newUserName.toLowerCase();

    setUser((currentUser) => ({
      ...currentUser,
      new_user_name: lowerCaseNewUserName,
    }));
    setNewUserName(lowerCaseNewUserName);
    setIsEditing(false);
    fetchAndSaveName({
      user_name: user.name,
      new_user_name: lowerCaseNewUserName,
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleProfileOpen = async () => {
    setProfileOpen(true);
    setScoreOpen(false);
    setErrorMessage(null);
  };

  const handleScoreOpen = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/me`,
        { withCredentials: true }, // Important: allow cookies in requests
      );

      setScoreOpen(true);
      setProfileOpen(false);
    } catch (error) {
      console.log(error.response);
      navigate("/reject-access", { state: { reason: "idle" } });
    }
  };

  const fetchAndSaveName = async ({ user_name, new_user_name }) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/changeUserInfo`,
        {
          params: { user_name: user_name, new_user_name: new_user_name },
        },
      );

      setUser((currentUser) => ({
        ...currentUser,
        name: new_user_name,
      }));
      setErrorMessage("");
    } catch (error) {
      let message = "An unexpected error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 400) {
          message =
            error.response.data.message === "User name already in use."
              ? "Username taken"
              : error.response.data.message;
        }
      }
      setErrorMessage(message);
    }
  };

  const fetchAndStoreSubtopicScore = async (subtopic) => {
    if (!user) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/receiveScore`,
        {
          params: {
            user_email: user.email.toLowerCase(),
            subtopic: subtopic,
          },
        },
      );
      const newScore = response.data[subtopic];

      if (newScore !== user[subtopic]) {
        // Only update if there's actually a difference
        setUser((currentUser) => ({
          ...currentUser,
          [subtopic]: newScore,
        }));
      }
    } catch (error) {
      console.error("An error occurred while fetching subtopic scores:", error);
    }
  };

  useOutsideClick("modal-content", () => {
    setIsEditing(false);
    setIsEditingNameModalOpen(false);
    setIsSettingsModalOpen(false);
  });

  React.useEffect(() => {
    if (!user) return; // If user is null or not loaded, do nothing.

    mainTopics.forEach((topic) => {
      topic.subtopics.forEach((subtopic) => {
        fetchAndStoreSubtopicScore(subtopic);
      });
    });
  }, [user]);

  useEscapeKeyHandler(() => {
    setIsEditing(false);
    setIsEditingNameModalOpen(false);
    setIsSettingsModalOpen(false);
  });

  return (
    <div className="min-h-screen bg-gray-50 p-4 dark:bg-gray-900">
      {/* Navigation */}
      <div className="justify-around rounded-lg border border-gray-200 p-4 text-blue-600 shadow-md dark:border-gray-700 md:flex">
        <h1
          className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400"
          onClick={handleProfileOpen}
        >
          My Profile
        </h1>
        <h1
          className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400"
          onClick={handleScoreOpen}
        >
          Scores
        </h1>
        <h1 className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400">
          Appearance
        </h1>
        <h1 className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400">
          Security
        </h1>
        <h1 className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400">
          Help
        </h1>
      </div>

      {/* Profile Section */}
      {profileOpen && user && (
        <div className="mt-2 flex flex-col rounded-lg border border-gray-200 bg-white text-black shadow-md dark:border-gray-700 dark:bg-gray-800 sm:items-center">
          {/* Email Field */}
          <div className="mb-4 w-full px-2 md:mb-0 md:w-1/2">
            <label
              htmlFor="email"
              className="text-lg font-semibold text-gray-700 dark:text-gray-300"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={user.email.toLowerCase()}
              readOnly
              className="w-full rounded-md border-2 border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600"
              required
            />
          </div>

          {/* Name Field */}
          <div className="mb-4 flex w-full px-2 md:mb-0 md:mt-4 md:w-1/2">
            {isEditing ? (
              <div className="w-full">
                <label
                  htmlFor="name"
                  className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value.trim())}
                  className="w-full rounded-md border-2 border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600"
                />
                {isEditingNameModalOpen && (
                  <div className="mt-2 flex">
                    <button
                      onClick={handleSave}
                      className="rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-200 focus:ring-opacity-50"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className="ml-2 rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-opacity-50"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex w-full items-center">
                <label
                  htmlFor="name"
                  className="mr-2 text-lg font-semibold text-gray-700 dark:text-gray-300"
                >
                  Name:
                </label>
                <span className="mr-2 text-gray-800 dark:text-gray-200">
                  {user.name}
                </span>
                <div className="flex flex-col">
                  <button
                    onClick={handleEdit}
                    className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
                  >
                    &#x270E; Edit
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Password Field */}
          <div className="flex w-full flex-col items-start px-2 md:w-1/2">
            <label
              htmlFor="password"
              className="mr-2 text-lg font-semibold text-gray-700 dark:text-gray-300"
            >
              Password:
            </label>
            <button
              className="mb-2 rounded-md bg-purple-500 px-4 py-2 text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:ring-opacity-50"
              onClick={handleSettingChange}
            >
              Settings
            </button>
            {isSettingsModalOpen && (
              <SettingsModal
                isOpen={isSettingsModalOpen}
                onClose={() => setIsSettingsModalOpen(false)}
                user={user}
              />
            )}
          </div>
        </div>
      )}

      {/* Error and Success Messages */}
      {errorMessage && !scoreOpen && (
        <div className="mx-auto mb-4 mt-4 max-w-md rounded-lg bg-red-500 p-4 text-center text-white">
          <strong>Error: </strong>
          {errorMessage}
        </div>
      )}
      {errorMessage === "" && !scoreOpen && (
        <div className="mx-auto mb-4 mt-4 max-w-md rounded-lg bg-green-500 p-4 text-center text-white">
          <strong>Name successfully changed</strong>
        </div>
      )}

      {/* Scores Section */}
      {scoreOpen && (
        <div className="m-5 flex flex-col rounded-lg border border-gray-200 bg-white p-4 shadow-md dark:border-gray-700 dark:bg-gray-800">
          {loading && (
            <p className="text-gray-700 dark:text-gray-300">
              Loading exercises...
            </p>
          )}
          {error && (
            <p className="text-red-500">Error loading exercises: {error}</p>
          )}
          {!loading &&
            !error &&
            mainTopics.map((topic) =>
              topic.subtopics.map((subtopic, index) => (
                <div
                  key={`${topic.name}-${index}`}
                  className="flex items-center py-2 text-gray-800 dark:text-gray-100"
                >
                  <span className="mr-2 font-semibold">
                    {subtopicDisplayNames[subtopic] || subtopic}:
                  </span>
                  <div className="flex-1"></div>

                  {user[subtopic] !== undefined ? (
                    <span className="whitespace-nowrap font-medium text-blue-600">
                      {user[subtopic]}/{max_score_mapping[subtopic]} (
                      {(
                        (user[subtopic] / max_score_mapping[subtopic]) *
                        100
                      ).toFixed(1)}
                      %)
                    </span>
                  ) : (
                    <span className="whitespace-nowrap font-medium text-gray-500">
                      Score not available
                    </span>
                  )}
                </div>
              )),
            )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
