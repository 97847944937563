import React, { useState } from "react";
import { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
import Tippy, { useSingleton } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const Dictionary = () => {
  const [text, setText] = useState("");
  const [chosenWord, setChosenWord] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [searchType, setSearchType] = useState("");
  const [isIframeLoading, setIsIframeLoading] = useState(false);

  // 1. Create the singleton source & target
  //    "overrides: ['interactive']" ensures individual Tippy tooltips remain interactive
  const [source, target] = useSingleton({
    overrides: ["interactive"],
  });

  // Remove punctuation
  const cleanWord = (word) => word.replace(/[.,/#!$%^&*;:@{}=\-_`~()]/g, "");

  const handleDefinitionClick = (rawWord) => {
    setIsIframeLoading(true); // Show loading overlay

    const word = cleanWord(rawWord);
    setChosenWord(word);
    setSearchType("definition");
    setReloadKey((prev) => prev + 1);
  };

  const handleCollocationClick = (rawWord) => {
    setIsIframeLoading(true); // Show loading overlay

    const word = cleanWord(rawWord);
    setChosenWord(word);
    setSearchType("collocation");
    setReloadKey((prev) => prev + 1);
  };

  // Manually hide Tippy after a button click (so it closes right away)
  const handleButtonClick = (event, rawWord, type) => {
    event.stopPropagation();
    const cleanedWord = cleanWord(rawWord);

    if (type === "definition") {
      handleDefinitionClick(cleanedWord);
    } else {
      handleCollocationClick(cleanedWord);
    }

    // Hide this Tippy instance
    const tippyRoot = event.currentTarget.closest("[data-tippy-root]");
    if (tippyRoot && tippyRoot._tippy) {
      tippyRoot._tippy.hide();
    }
  };

  const renderTippyWords = () => {
    const trimmed = text.trim();
    if (!trimmed) {
      return (
        <span className="text-gray-400">
          Hoverable/clickable text will appear here...
        </span>
      );
    }

    const words = trimmed.split(/\s+/);

    return (
      <p className="m-0 whitespace-normal break-normal">
        {words.map((word, index) => (
          // 3. Instead of <Tippy>, use <Tippy singleton={target}>
          <Tippy
            key={index}
            singleton={target}
            interactive={true}
            trigger="mouseenter click"
            // Optionally, you can use hideOnClick if desired
            content={
              <div className="flex items-start gap-2">
                <button
                  onClick={(e) => handleButtonClick(e, word, "definition")}
                  className="rounded bg-blue-500 px-2 py-1 text-white shadow"
                >
                  definitions
                </button>
                <button
                  onClick={(e) => handleButtonClick(e, word, "collocation")}
                  className="rounded bg-blue-500 px-2 py-1 text-white shadow"
                >
                  collocations
                </button>
              </div>
            }
          >
            <span className="mr-1 inline-block cursor-pointer text-blue-600 hover:underline">
              {word}
            </span>
          </Tippy>
        ))}
      </p>
    );
  };

  return (
    <div className="p-4">
      {/* Title */}
      <div className="text-center">
        <h1 className="mb-2 text-2xl font-bold">Word Click Dictionary</h1>
        <p className="text-gray-700">
          Paste or type your text into the box below. Then hover or click any
          word to see its definition in the dictionary.
        </p>
      </div>

      {/* Input / Display */}
      <div className="flex flex-col gap-8 p-6 sm:flex-row">
        <textarea
          placeholder="Paste or type your text here..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="h-64 w-full max-w-2xl resize-none rounded-md border border-gray-300 p-4 text-base shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex items-center justify-center">
          <ArrowDownIcon className="h-8 w-8 text-gray-400" />
        </div>

        <div className="max-h-64 min-h-[3rem] w-full max-w-xl overflow-auto rounded border border-gray-200 bg-gray-50 p-2">
          {/* 2. Wrap "source" around the Tippy-wrapped content */}
          <Tippy singleton={source} moveTransition="transform 0.2s ease-out">
            {renderTippyWords()}
          </Tippy>
        </div>

        <div className="flex items-center justify-center">
          <ArrowDownIcon className="h-8 w-8 text-gray-400" />
        </div>
      </div>

      {/* Iframe & Buttons */}
      <div className="flex flex-col items-center">
        <div className="mb-2 flex items-center gap-2 text-gray-500">
          <button
            onClick={() => handleDefinitionClick(chosenWord)}
            className="border border-blue-500 px-2 py-1"
          >
            definitions
          </button>
          <button
            onClick={() => handleCollocationClick(chosenWord)}
            className="border border-blue-500 px-2 py-1"
          >
            collocations
          </button>
        </div>
        <iframe
          key={reloadKey}
          src={
            searchType === "definition"
              ? `https://www.merriam-webster.com/dictionary/${chosenWord}`
              : `https://www.freecollocation.com/search?word=${chosenWord}`
          }
          height="600"
          allowFullScreen
          onLoad={() => setIsIframeLoading(false)}
          className="w-full rounded-md border border-gray-300 shadow-md sm:w-4/5"
          title="Dictionary"
        />
        {/* OVERLAY that shows while isIframeLoading === true */}
        {isIframeLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/80">
            <h1 className="text-xl text-gray-700">Loading...</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dictionary;
