import React from "react";
import { Link } from "react-router-dom";

interface NavLinkProps {
  to: string;
  label: string;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, label, onClick }) => {
  return onClick ? (
    <button onClick={onClick} className="ml-[16px] mr-[16px]">
      {label}
    </button>
  ) : (
    <Link to={to} className="ml-[16px] mr-[16px]">
      {label}
    </Link>
  );
};

export default NavLink;
