// src/data/sentencesData.ts

import { Sentence } from './Sentence'; // Adjust the path based on your project structure


export const sentences: Sentence[] = [
    {
        "uniqueId": "A-1",
        "title": "A",
        "isParagraphStart": true,
        "id": 1,
        "text": "Burnout among the workforce has become increasingly problematic in recent years.",
        "annotation": "Presents brief background: 'Burnout is increasingly problematic.'",
        "icon": "tick"
    },
    {
        "uniqueId": "A-2",
        "title": "A",
        "isParagraphStart": false,
        "id": 2,
        "text": "According to Browner (2022), burnout is a global issue and can be associated with constant feelings of fatigue, tiredness, and depression, with a majority of the workforce approximately 70% of workers experiencing this phenomenon. Some root causes of burnout include inadequate compensation relative to the labor contributed and harsh treatment by colleagues.",
        "annotation": "Defines the key term in the essay ‘burnout’.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-3",
        "title": "A",
        "isParagraphStart": false,
        "id": 3,
        "text": "By understanding these causes, companies can implement preventive measures, such as fair compensation for effort and bullying prevention.",
        "annotation": "Outlines the problems and solutions that will be presented in the essay.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-4",
        "title": "A",
        "isParagraphStart": false,
        "id": 4,
        "text": "This essay explores the causes of workplace burnout and suggests solutions to address these underlying problems.",
        "annotation": "Gives an overview of the essay. The writer has paraphrased the question and not just copied the 'title'.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-5",
        "title": "A",
        "isParagraphStart": true,
        "id": 5,
        "text": "A major contributor to burnout among employees in the workplace is effort-reward imbalance.",
        "annotation": "Gives a clear topic sentence (effort-reward imbalance).",
        "icon": "tick"
    },
    {
        "uniqueId": "A-6",
        "title": "A",
        "isParagraphStart": false,
        "id": 6,
        "text": "Rugulies et al. (2017) reveals that when the effort exerted by employees is not adequately compensated or recognized, it can lead to feelings of unappreciation, degradation, and diminished self-worth, which may ultimately result in depression.",
        "annotation": "Explains the key term (effort-reward imbalance) and the problems it causes.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-7",
        "title": "A",
        "isParagraphStart": false,
        "id": 7,
        "text": "Effort-reward imbalance has also been observed to be linked to a 1.5 times increase in depression rates, negatively affecting the performance and productivity of workers.",
        "annotation": "Better to give a source for statistics.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "A-8",
        "title": "A",
        "isParagraphStart": false,
        "id": 8,
        "text": "When employees perceive that their efforts and dedication are not met with appropriate rewards whether financial compensation, promotions, or recognition from colleagues and management they may experience dissatisfaction or disappointment.",
        "annotation": "Elaborates on the previous two sentences.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-9",
        "title": "A",
        "isParagraphStart": false,
        "id": 9,
        "text": "This can increase the likelihood of developing depression, fatigue, or exhaustion, which are common diagnoses of burnout.",
        "annotation": "",
        "icon": "NA"
    },
    {
        "uniqueId": "A-10",
        "title": "A",
        "isParagraphStart": false,
        "id": 10,
        "text": "Companies often provide minimal compensation for employees' efforts, such as yoga classes or wellness apps.",
        "annotation": "Introduces current solutions.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-11",
        "title": "A",
        "isParagraphStart": false,
        "id": 11,
        "text": "However, these solutions tend to be short-term fixes for deeper issues that remain unaddressed.",
        "annotation": "",
        "icon": "NA"
    },
    {
        "uniqueId": "A-12",
        "title": "A",
        "isParagraphStart": false,
        "id": 12,
        "text": "(Christian, 2023).",
        "annotation": "The citation appears to be in the wrong position. It should come at the end of the previous sentence.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "A-13",
        "title": "A",
        "isParagraphStart": false,
        "id": 13,
        "text": "By ignoring these root causes, companies might jeopardize their own success, as employees experiencing burnout will likely see reduced productivity and diminished loyalty, which subsequently affects their responsibilities at work and increases turnover rates, leading to further challenges for the organization.",
        "annotation": "Explains how this issue affects companies.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-14",
        "title": "A",
        "isParagraphStart": true,
        "id": 14,
        "text": "To address the problem of burnout among workers, companies should implement counterstrategies.",
        "annotation": "Links to the previous paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-15",
        "title": "A",
        "isParagraphStart": false,
        "id": 15,
        "text": "One effective approach to reduce the impact of burnout caused by a lack of rewards relative to effort is to introduce a comprehensive reward system.",
        "annotation": "Introduces the main idea of the paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-16",
        "title": "A",
        "isParagraphStart": false,
        "id": 16,
        "text": "Nkouangas (2023) observed that both financial rewards, such as bonuses, and forms of recognition, such as employee of the month, are well received by workers, who appreciate that their dedication is acknowledged.",
        "annotation": "Explains the idea (comprehensive reward system) introduced in the previous sentence.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-17",
        "title": "A",
        "isParagraphStart": false,
        "id": 17,
        "text": "He noted, “Employees enthusiastically support salary increases, which motivate them to work harder,” illustrating that when companies value their employees' efforts, it fosters motivation",
        "annotation": "Gives a quotation to support the idea.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-18",
        "title": "A",
        "isParagraphStart": false,
        "id": 18,
        "text": "(p. 23).",
        "annotation": "Gives page number for direct quotation.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-19",
        "title": "A",
        "isParagraphStart": false,
        "id": 19,
        "text": "Employers should consider implementing such measures, as motivated workers contribute positively to the company. Compensation plays a significant role in enhancing employee performance (Riaz & Syed, 2020). Improved performance and productivity among employees can lead to increased efficiency, innovation, and overall profitability for the businesses.",
        "annotation": "Further explain the argument introduced by the quotation.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-20",
        "title": "A",
        "isParagraphStart": false,
        "id": 20,
        "text": "Furthermore, fostering motivation helps decrease the likelihood of employees feeling undervalued or discouraged, thereby reducing the chances of depression or burnout and promoting a more productive and effective atmosphere for the company.",
        "annotation": "Introduces a second argument (stops problems occurring).",
        "icon": "tick"
    },
    {
        "uniqueId": "A-21",
        "title": "A",
        "isParagraphStart": false,
        "id": 21,
        "text": "This investment in employees' well being not only enhances their morale but is also beneficial in the organization's long term success.",
        "annotation": "Gives a concluding sentence.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-22",
        "title": "A",
        "isParagraphStart": true,
        "id": 22,
        "text": "In addition to the disparity between compensation and the effort employees invest, another significant factor contributing to workplace dissatisfaction is the harsh treatment many receive from their colleagues.",
        "annotation": "Gives a clear topic sentence with a paragraph hook and a clear controlling idea (workplace bullying).",
        "icon": "tick"
    },
    {
        "uniqueId": "A-23",
        "title": "A",
        "isParagraphStart": false,
        "id": 23,
        "text": "Workplace bullying has a detrimental effect on the overall work environment, leading to a range of negative consequences for victims.",
        "annotation": "Explains that the ‘harsh treatment’ introduced in the previous sentence",
        "icon": "tick"
    },
    {
        "uniqueId": "A-24",
        "title": "A",
        "isParagraphStart": false,
        "id": 24,
        "text": "Those who experience bullying often face a dramatic decline in motivation, an increase in mental health issues, reduced job satisfaction, and diminished commitment to their organization (Teo et al, 2019). Being subjected to such bullying creates a hostile work environment, where victims must contend with constant stress from negative interactions. This persistent strain can significantly increase the chances of burnout.",
        "annotation": "Lists the negative consequences of bullying and links it to burnout.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-25",
        "title": "A",
        "isParagraphStart": false,
        "id": 25,
        "text": "Ribeiro et al (2022) notes that “workplace bullying promotes high levels of burnout in victims” (p. 832).",
        "annotation": "Provides evidence for the claims made in the previous sentence.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-26",
        "title": "A",
        "isParagraphStart": false,
        "id": 26,
        "text": " The adverse effects of bullying extend beyond individual employees, ultimately impairing performance during their shifts and negatively impacting overall company productivity. Thus, both the employees and the organization suffer as a result of this toxic dynamic.",
        "annotation": "Provides summary sentences.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-27",
        "title": "A",
        "isParagraphStart": true,
        "id": 27,
        "text": "Regarding workplace aggression,",
        "annotation": "'Regarding ..' to  link ideas  is better avoided in academic writing.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "A-28",
        "title": "A",
        "isParagraphStart": false,
        "id": 28,
        "text": "organizations should consider implementing bullying prevention measures. To combat unacceptable such behavior, anti-bullying policies must be prioritized.",
        "annotation": "States the main idea of the paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-29",
        "title": "A",
        "isParagraphStart": false,
        "id": 29,
        "text": "These measures have proven effective in reducing bullying within the workforce (Jang et al, 2022).",
        "annotation": "Provides support by citing an expert.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-30",
        "title": "A",
        "isParagraphStart": false,
        "id": 30,
        "text": "To protect victims, anti-bullying strategies should include open communication between employees and employers, detailed reporting procedures, and training for both staff and management. To deter harassment, companies need to establish clear consequences for such behavior.",
        "annotation": "Explains the anti-bullying measures.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-31",
        "title": "A",
        "isParagraphStart": false,
        "id": 31,
        "text": "Additionally, allowing anonymous reporting can help protect the victim's identity. Once reports are received, the organization can initiate an investigation to verify the claims if substantiated, appropriate action can be taken against the aggressor. Implementing these measures will increase awareness of employee's actions and the potential consequences (Strandmark & Rahm, 2014).",
        "annotation": "Explains why and how the measures will be effective.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-32",
        "title": "A",
        "isParagraphStart": false,
        "id": 32,
        "text": "By decreasing the rates of bullying in the workplace, organizations can mitigate the negative impacts on employees, thereby reducing the likelihood of burnout in this environment.",
        "annotation": "Closes the paragraph by restating the major argument.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-33",
        "title": "A",
        "isParagraphStart": true,
        "id": 33,
        "text": "To conclude, the issue of burnout among the workforce arises from effort-reward imbalances and workplace bullying.",
        "annotation": "Restates the problem (i.e., the two causes).",
        "icon": "tick"
    },
    {
        "uniqueId": "A-34",
        "title": "A",
        "isParagraphStart": false,
        "id": 34,
        "text": "Implementing an effective reward system whether through financial incentives or recognition alongside anti-bullying strategies aimed at protecting victims is essential for reducing burnout rates. Companies must invest more effort and resources into creating a healthier working environment, as this would not only support employees but also boost the company performance.",
        "annotation": "Summarises proposed solutions.",
        "icon": "tick"
    },
    {
        "uniqueId": "A-35",
        "title": "A",
        "isParagraphStart": false,
        "id": 35,
        "text": "By addressing these critical factors, businesses can foster a more motivated and engaged workforce. Investing in these areas is crucial for reducing burnout rates and fostering a more productive and engaged workforce. Furthermore, raising awareness can lead to a global recognition of such issues and prompt actions to protect the employees.",
        "annotation": "Restates writer’s main idea in the closing sentence.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-1",
        "title": "B",
        "isParagraphStart": true,
        "id": 1,
        "text": "Sleep deprivation refers to a continuous pattern of inadequate sleep duration or quality. It cumulates adverse effects on various aspects of one’s well-being, called “sleep debt” (Wise, 2018).",
        "annotation": "Defines the topic and gives a citation to add academic credibility.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-2",
        "title": "B",
        "isParagraphStart": false,
        "id": 2,
        "text": "It is known that university students are prone to suffer from sleep deprivation.",
        "annotation": "Better place background sentences at the start of the paragraph.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-3",
        "title": "B",
        "isParagraphStart": false,
        "id": 3,
        "text": "Research has found that students usually sleep at around 2:30am, sleeping for 6.5 hours on average (Amenabar, 2023).",
        "annotation": "Gives a citation to add academic credibility and avoid plagiarism.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-4",
        "title": "B",
        "isParagraphStart": false,
        "id": 4,
        "text": "This means that most university students are likely to be sleep-deprived.",
        "annotation": "",
        "icon": "NA"
    },
    {
        "uniqueId": "B-5",
        "title": "B",
        "isParagraphStart": false,
        "id": 5,
        "text": "Another study indicates that there is a negative correlation between the amount of sleep and working memory and executive function (Duplessis, 2024). Linking both studies, it shows that sleep deprivation may pose adverse effects on academic performance of university students.",
        "annotation": "Introduces why this is a problem.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-6",
        "title": "B",
        "isParagraphStart": false,
        "id": 6,
        "text": "A relatively poor grade-point-average and the incapability to participate in lectures would be discussed below. To alleviate the negative effects, caffeine consumption and adding nap stations on campus should be taken into consideration.",
        "annotation": "Outlines the content of the essay. This would be better if it contained a paraphrase of the main idea of each paragraph.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-7",
        "title": "B",
        "isParagraphStart": true,
        "id": 7,
        "text": "The major effect of sleep deprivation among university students is a relatively poor grade-point-average (GPA).",
        "annotation": "Gives topic sentence stating the main idea of the paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-8",
        "title": "B",
        "isParagraphStart": false,
        "id": 8,
        "text": "Students need good quality and a sufficient amount of sleep to perform well in their studies, as basic brain functions like memorization, attention span, processing and retrieval of knowledge requires sleep, and these functions are most likely necessary for tertiary education (Amenabar, 2023). It is especially important for mid-term tests and examinations, as students usually need to memorize and understand theories or concepts, then apply them on questions that involve some type of analysis or logical thinking, which means that grades are mostly dependent on students’ ability to memorize and ponder.",
        "annotation": "Explains the importance of sleep.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-9",
        "title": "B",
        "isParagraphStart": false,
        "id": 9,
        "text": "According to a study of factors influencing academic performance within undergraduate students, ‘‘Sound and enough sleep are important to understand the subject and tackle the exam well.’’ (p.46) is quoted from a student (Abdulghani et al., 2014).",
        "annotation": "Provides a quotation to support the main idea.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-10",
        "title": "B",
        "isParagraphStart": false,
        "id": 10,
        "text": "This shows that sleep is a crucial factor that determines one’s comprehension skills, hence affecting GPA.",
        "annotation": "Explains the previous sentence using a summary phrase 'This shows…'.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-11",
        "title": "B",
        "isParagraphStart": false,
        "id": 11,
        "text": "Moreover, some statistical data can also suggest that sleep duration of undergraduates directly correlates with their GPA.",
        "annotation": "Better to avoid being vague, e.g. “Some statistical data…”.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-12",
        "title": "B",
        "isParagraphStart": false,
        "id": 12,
        "text": " A study of effects of chronic sleep deprivation on academic performance states that there is a significant difference shown statistically between students with excellent and average grades concerning excessive daytime sleepiness (Mirghani et al., 2015). Some situations are used to test participants’ sleepiness, 98.3% and 77.1% of average students and excellent students respectively shows signs of sleepiness for “Lying down in the afternoon” (p.469), 81.7% and 52% of average students and excellent students respectively for “Watching TV” (p.469). These percentages show a significant difference between both categories, all indicating that excessive daytime sleepiness is more likely to be found within students with average grades than excellent grades.",
        "annotation": "Provides statistics to support the main idea. It would be better to make the contrast clearer, e.g., ‘While 98.3% of average students showed signs of daytime sleepiness, the number was lower for excellent students (77.1%).’",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-13",
        "title": "B",
        "isParagraphStart": false,
        "id": 13,
        "text": "Therefore, it suggests that sleep deprivation may contribute to a lower GPA.",
        "annotation": "Summarizes the main idea of the paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-14",
        "title": "B",
        "isParagraphStart": true,
        "id": 14,
        "text": "To address the effect of poor GPA caused by sleep deprivation, it is recommended that caffeine consumption can be a short-term solution to reduce students’ daytime sleepiness.",
        "annotation": "Gives topic sentence with main idea (drink coffee) and links paragraph 2 and 3.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-15",
        "title": "B",
        "isParagraphStart": false,
        "id": 15,
        "text": "Considering mid-term tests and examinations as the main criteria of assessment of academic performance, caffeine consumption can instantly alleviate drowsiness, helping students to stay alert during exams.",
        "annotation": "Explains how caffeine can help.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-16",
        "title": "B",
        "isParagraphStart": false,
        "id": 16,
        "text": "According to an investigation on effects of caffeine on sleep, results show that caffeine intake lessens the feeling of insufficient sleep, which could act as a solution to help students attain a higher GPA (Reichert et al., 2020).",
        "annotation": "Provides statistical support with a clear citation.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-17",
        "title": "B",
        "isParagraphStart": false,
        "id": 17,
        "text": "Despite potential downside of caffeine consumption mentioned in the study, this can act as an instant solution to improve sleep-deprived students’ GPA.",
        "annotation": "Includes a new idea in the final sentence of the paragraph (the potential downsides). Better to explain this further.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-18",
        "title": "B",
        "isParagraphStart": true,
        "id": 18,
        "text": "Another negative effect of sleep deprivation on undergraduate students is the probable incapability to participate and focus on lectures.",
        "annotation": "Better if the topic sentence includes ‘attendance’ which is also discussed in the paragraph.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-19",
        "title": "B",
        "isParagraphStart": false,
        "id": 19,
        "text": "A continuous cycle of insufficient sleep might develop a “sleep debt” gradually, which impairs students' ability to concentrate and engage with the class (Amenabar, 2023). Since the common symptoms of daytime sleepiness are dozing and falling asleep, students might doze off in the middle of lectures or zone out as attention spans are reduced. This might result in students not engaging and focusing during class, unable to fight off the urge to fall asleep.",
        "annotation": "Explains poor in-class engagement with citation to support the argument.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-20",
        "title": "B",
        "isParagraphStart": false,
        "id": 20,
        "text": "The study of effects of chronic sleep deprivation mentioned above, shows that the chance of dozing was the highest while sitting and reading (88%) which directly determine students’ capability to participate, as this is usually the learning format of tertiary education (Mirghani et al., 2015).",
        "annotation": "Provides statistics to support the main idea.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-21",
        "title": "B",
        "isParagraphStart": false,
        "id": 21,
        "text": " Moreover, attendance appears to be an important factor regarding academic performance. Abdulghani et al. (2014) quoted another undergraduate stating that attendance of lectures, and the ability to concentrate while absorbing new concepts is crucial to actually learn the information.",
        "annotation": "",
        "icon": "NA"
    },
    {
        "uniqueId": "B-22",
        "title": "B",
        "isParagraphStart": false,
        "id": 22,
        "text": "Therefore, sleep deprivation may influence students’ incapability to participate and focus on lectures.",
        "annotation": "Summarizes main points. However, it does not include all the main points because'attendance' is not mentioned.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-23",
        "title": "B",
        "isParagraphStart": true,
        "id": 23,
        "text": "To address the effect of incapability to participate in class caused by sleep deprivation, it is recommended to add napping stations to university campus.",
        "annotation": "Gives clear topic sentence with main idea ‘adding napping stations'.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-24",
        "title": "B",
        "isParagraphStart": false,
        "id": 24,
        "text": "Wise (2018) stated that compared to test respondents who did not nap at all after learning, those who napped right away and those whose naps were postponed performed significantly better on recall and relational interpretation. This shows that napping could be beneficial to students’ engagement during lectures and to actually learn in class. Wise (2018) suggests that undergraduates might nap unintentionally during lectures or while working, therefore access to napping stations can make napping on campus feasible, which encourages students to nap intentionally. This can help students deal with sleep deprivation, not only can they regain a boost of energy to get through the day, but also avoid dozing off in class.",
        "annotation": "Explains how napping stations improve support.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-25",
        "title": "B",
        "isParagraphStart": false,
        "id": 25,
        "text": "Wise (2018) mentions that some universities in United States provide napping stations or areas assigned for napping, from technologically advanced pods to beanbags to cots to recliners were provided.",
        "annotation": "Explains what napping stations are. This would be better earlier in the paragraph.",
        "icon": "light_bulb"
    },
    {
        "uniqueId": "B-26",
        "title": "B",
        "isParagraphStart": false,
        "id": 26,
        "text": " Therefore, more universities can use the same concept and add napping stations around campus, potentially enabling students to focus during class.",
        "annotation": "Summarises the main idea of the paragraph.",
        "icon": "tick"
    },
    {
        "uniqueId": "B-27",
        "title": "B",
        "isParagraphStart": true,
        "id": 27,
        "text": "In conclusion, a relatively poor GPA and lack of engagement in class are caused by sleep deprivation within undergraduate students. They may contemplate on consuming caffeine as a short-term solution to boost their attention, while universities could consider adding some napping stations or creating some designated areas for students to retrieve their energy, thus enhancing overall academic performance.",
        "annotation": "Restates the problems and solutions; It would be better to include a concluding sentence.",
        "icon": "light_bulb"
    }
  ];
