// Reusable Input Component
const InputField = ({ type, id, name, value, onChange }) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      className="w-full border-2 border-black text-black"
      onChange={onChange}
      required
    />
  );
};

export default InputField;
