import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { mainTopics } from "./topics";

const Exercises = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [availableTopics, setAvailableTopics] = useState([]);
  const [isCurrentSubtopicAvailable, setIsCurrentSubtopicAvailable] =
    useState(false);
  const [isAnySubtopicSelected, setIsAnySubtopicSelected] = useState(false);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/titles`,
        );
        setAvailableTopics(response.data.uniqueTitles);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, []);

  useEffect(() => {
    if (from) {
      const matchingTopic = mainTopics.find((topic) =>
        topic.subtopics.includes(from),
      );
      if (matchingTopic) {
        handleTopicSelection(matchingTopic);
      }
    }
  }, [from]);

  const handleSubTopicSelection = (subtopic) => {
    setIsAnySubtopicSelected(true);
    if (availableTopics.includes(subtopic)) {
      setIsCurrentSubtopicAvailable(true);
      handleNavigateToSubtopic(subtopic);
    } else {
      setIsCurrentSubtopicAvailable(false);
    }
  };

  const handleTopicSelection = (topic) => {
    setSelectedTopic(topic);
    setIsAnySubtopicSelected(false);
    setIsCurrentSubtopicAvailable(false);
  };

  const handleBackToMainTopics = () => {
    setSelectedTopic(null);
    setIsAnySubtopicSelected(false);
    setIsCurrentSubtopicAvailable(false);
  };

  const handleNavigateToSubtopic = (subtopic) => {
    navigate(`/${subtopic}`);
    setIsCurrentSubtopicAvailable(true);
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-50 p-6 dark:bg-customDark">
      {/* Header */}
      <h1 className="mb-8 text-center text-3xl font-bold text-gray-800 dark:text-lightGray">
        Identify and Correct Errors
      </h1>
      <div className="flex flex-1 flex-col gap-6 md:flex-row">
        {/* Topics List */}
        <div className={`flex-1 ${selectedTopic && "hidden md:block"}`}>
          {mainTopics.map((topic) => (
            <div
              key={topic.name}
              onClick={() => handleTopicSelection(topic)}
              className="mb-6 transform cursor-pointer rounded-lg bg-white p-6 shadow-lg transition-transform hover:scale-105 hover:shadow-xl dark:bg-veryDark"
            >
              <span className="text-xl font-semibold text-gray-800 dark:text-lightGray">
                {topic.name}
              </span>
            </div>
          ))}
        </div>

        {/* Subtopics or Feedback */}
        {(selectedTopic || window.innerWidth >= 768) && (
          <div className="flex-1">
            {selectedTopic && (
              <button
                onClick={handleBackToMainTopics}
                className="mb-6 flex items-center text-indigo-500 hover:text-indigo-600 md:hidden"
              >
                ← Back to Topics
              </button>
            )}

            {isAnySubtopicSelected && !isCurrentSubtopicAvailable ? (
              <div className="flex h-32 items-center justify-center rounded-lg bg-red-100 p-6 text-red-800 shadow-md dark:bg-red-900 dark:text-red-200">
                <span className="text-center text-lg font-semibold">
                  This exercise is not available yet.
                </span>
              </div>
            ) : (
              selectedTopic?.subtopics.map((subtopic) => (
                <div
                  key={subtopic}
                  onClick={() => handleSubTopicSelection(subtopic)}
                  className="mb-6 flex transform cursor-pointer items-center rounded-lg bg-white p-6 shadow-lg transition-transform hover:scale-105 hover:shadow-xl dark:bg-veryDark"
                >
                  <span className="text-lg font-medium text-gray-800 dark:text-lightGray">
                    {subtopic
                      .split("-")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")}
                  </span>
                  <svg
                    className="ml-auto h-6 w-6 text-gray-500 dark:text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Exercises;
