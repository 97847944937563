import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { UserContext } from "../App";
import axios from "axios";

const PrivateRoute = ({ children, requireSpecificAccess }) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    setIsAuthenticated,
    isAuthenticated,
    userPermissions,
    setUserPermissions,
    setIsAuthorized,
    isAuthorized,
  } = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    const verifyUserSession = async () => {
      try {
        // 1) Check if user is logged in
        await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/me`, {
          withCredentials: true,
        });

        // 2) Check if user has specific access
        if (requireSpecificAccess) {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/regex`,
            {
              withCredentials: true,
            },
          );
          if (response.status === 200) {
            console.log("reached 200");
            setIsAuthorized(true);
            setIsAuthenticated(true);
            setUserPermissions((prev) => ({ ...prev, taggerAccess: true }));
            setIsLoading(false);
          }
        } else {
          console.log("reached NO specific requirement");
          setIsAuthenticated(true);
          setIsAuthorized(true);

          setIsLoading(false);
        }
      } catch (error) {
        // This block will catch 403 errors (or anything else thrown by axios)
        if (error.response && error.response.status === 403) {
          // The user is logged in but does NOT have taggerAccess
          setIsAuthorized(false);
          setIsAuthenticated(true); // They are still authenticated
          setUserPermissions((prev) => ({ ...prev, taggerAccess: false }));
          setIsLoading(false);
        } else if (error.response && error.response.status === 401) {
          // The user is truly not logged in
          setIsAuthenticated(false);
          setIsLoading(false);
        } else {
          // Handle any other error codes
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      }
    };

    verifyUserSession();
  }, [requireSpecificAccess, setIsAuthenticated]);

  // While we’re verifying, show a loading indicator or blank
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If the server confirms the user is valid, render the child route
  if (isAuthorized === true && isAuthenticated === true) {
    // If user is both logged in and authorized for this route,
    console.log("H1");

    // allow them to see the children
    return children;
  } else if (isAuthorized === false && isAuthenticated === true) {
    // User is logged in but not authorized
    console.log("H2");
    return (
      <Navigate to="/reject-access" state={{ isAuthorized: false }} replace />
    );
  } else {
    console.log("H3");
    return <Navigate to="/sign-in" replace />;
  }
};

export default PrivateRoute;
