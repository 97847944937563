import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import InputField from "./InputField";
import "./SignInWindow.css";
const SignIn = ({ onLogin, setUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidLogin, setIsValidLogin] = useState(false);
  const [loginFailedMessage, setLoginFailedMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    if (isValidLogin) {
      setIsLoggingIn(false);
      navigate(from);
    }
  }, [isValidLogin]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setIsLoggingIn(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: email, password: password }),
          credentials: "include", // Include cookies in the request¶
        },
      );

      const data = await response.json();

      if (response.ok) {
        onLogin();
        setUser({ email: data.user_email, name: data.user_name });

        setIsValidLogin(true);
      } else {
        setLoginFailedMessage(data.message);
        setIsValidLogin(false);
        setIsLoggingIn(false);
      }
    } catch (error) {
      console.error("Error during sign in:", error);
      alert("Network error during sign in");
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="signin-div grow justify-center">
      {!isValidLogin && loginFailedMessage && (
        <div
          className="display-login-reject mb-4 border-l-4 border-red-500 bg-red-100 p-4 text-red-700"
          role="alert"
        >
          <h1 className="text-lg font-semibold">{loginFailedMessage}</h1>
        </div>
      )}
      {isValidLogin && (
        <div
          className="mb-4 rounded border-l-4 border-green-500 bg-green-100 p-4 text-green-700 shadow-md"
          role="alert"
        >
          <h1 className="text-lg font-semibold">
            Login successful! <br />
          </h1>
        </div>
      )}
      <h1 className="text-center text-2xl font-bold text-gray-800">Sign In</h1>

      <div className="flex w-[100%] items-center justify-center"></div>
      <form className="form-div" onSubmit={handleSignIn}>
        <label htmlFor="email">Email:</label>
        <InputField
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isValidLogin || isLoggingIn}
        />
        <label htmlFor="password">Password:</label>
        <InputField
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={isValidLogin || isLoggingIn}
        />
        <button
          type="submit"
          className={`rounded bg-blue-500 px-4 py-2 font-bold text-white shadow-lg transition duration-300 hover:bg-blue-700 hover:shadow-xl ${
            isLoggingIn ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isLoggingIn || isValidLogin}
        >
          {isLoggingIn ? "Logging in..." : "Sign In"}
        </button>
        <Link to="/forgot-password" className="mt-4">
          Forgot Password?
        </Link>
      </form>
    </div>
  );
};

export default SignIn;
