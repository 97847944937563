import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Regex.css";

const Regex = () => {
  const [data, setData] = useState({
    regex: "",
    testcase: "",
    annotatedTestcase: "",
  });
  const [inputRegex, setInputRegex] = useState("");

  const [testcase, setTestcase] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // if (!inputRegex || !testcase) return; // Do not send empty values

      try {
        console.log("Tagger:" + process.env.REACT_APP_TAGGER_ENDPOINT);
        const response = await axios.post(
          `${process.env.REACT_APP_TAGGER_ENDPOINT}/tagger`,
          {
            input: testcase,
          },
        );

        let annotatedTestcase = response.data.annotatedText;
        const regex = new RegExp(inputRegex, "gim");
        annotatedTestcase = annotatedTestcase.replace(
          regex,
          (match) => `<span style="background-color: yellow;">${match}</span>`,
        );

        setData({
          regex: inputRegex,
          testcase: testcase,
          annotatedTestcase: annotatedTestcase,
        });
      } catch (error) {
        console.error("Error annotating text:", error);
      }
    };

    fetchData();
  }, [inputRegex, testcase]);

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <label
          style={{ marginRight: "20px", display: "block", margin: "10px 0" }}
        >
          Input Regex: <br />
          <textarea
            style={{
              width: "80%", // Set width relative to the parent element
              padding: "8px",
              fontSize: "16px",
              margin: "5px 0",
              overflowWrap: "break-word",
              resize: "vertical",
              // whiteSpace: "pre-wrap",
              height: "100px", // Optional: Set a maximum height\
              lineHeight: "1.6",
            }}
            value={inputRegex}
            onChange={(e) => setInputRegex(e.target.value)}
          />
        </label>
        <label
          style={{ marginRight: "20px", display: "block", margin: "10px 0" }}
        >
          Testcase: <br />
          <textarea
            style={{
              width: "80%", // Set width relative to the parent element
              padding: "8px",
              fontSize: "16px",
              margin: "5px 0",
              overflowWrap: "break-word",
              resize: "vertical",
              // whiteSpace: "pre-wrap",
              height: "100px", // Optional: Set a maximum height\
              lineHeight: "1.6",
            }}
            value={testcase}
            onChange={(e) => setTestcase(e.target.value)}
          />
        </label>
      </div>

      <table style={{ width: "80%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Input Regex
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Testcase
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Annotated Testcase
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                maxWidth: "200px",

                border: "1px solid #ddd",
                padding: "8px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {data.regex}
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                maxWidth: "200px",
                border: "1px solid #ddd",
                padding: "8px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {data.testcase}
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                border: "1px solid #ddd",
                maxWidth: "200px",
                border: "1px solid #ddd",
                padding: "8px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: data.annotatedTestcase }}
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Regex;
